import React from "react";

const BackGradient = ({ colorStart, colorStop }) => {
  return (
    <linearGradient id="backGradient" x1="0" x2="1" y1="0" y2="1">
      <stop offset="0" stopColor={colorStart}></stop>
      <stop offset="1" stopColor={colorStop}></stop>
    </linearGradient>
  );
};

export default BackGradient;
