import React from "react";

function Refresh() {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Refresh</title>
      <g>
        <path
          d="M14.5,8a6.5,6.5,0,1,1-1.022-3.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          fill="none"
          points="13.5 0.5 13.5 4.5 9.5 4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default Refresh;
