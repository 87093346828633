import { lerp, intLerp, invcubcoserp } from "./helpers/math";

const generateConfig = (dimensions, variables) => {
  // Calculate dimesions
  const side = Math.max(dimensions.height, dimensions.width);
  const diagonal = Math.sqrt(2 * (side * side));

  // Calculate grid
  const numRows = variables.resolution;
  const barThickness = diagonal / numRows;
  const numCols = Math.floor(diagonal / barThickness);
  const barLength = Math.max(numRows, numCols);

  // Map expressiveness to value between 0 and 1
  const xpness = variables.expressiveness * 0.01;

  // Define visual parameters based on expressiveness
  const maxBars = Math.round(barLength * invcubcoserp(0.85, 0.35, xpness));
  const extraBars = intLerp(0, 10, xpness);
  const curveThreshold = invcubcoserp(0.05, 0.8, xpness);
  const noiseDensity = lerp(0.2, 1.5, xpness);
  const noiseThreshold = lerp(0.05, 0.3, xpness);
  const distMean = 0.45;
  const distStdDev = lerp(0.26, 0.08, xpness);

  return {
    diagonal,
    xpness,
    numRows,
    numCols,
    barThickness,
    barLength,
    maxBars,
    extraBars,
    curveThreshold,
    noiseDensity,
    noiseThreshold,
    distMean,
    distStdDev,
  };
};

export default generateConfig;
