import React from "react";

function Copy() {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Copy</title>
      <g>
        <rect
          height="12"
          width="10"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="2.5"
          y="3.5"
        />
        <polyline
          fill="none"
          points="4.5,0.5 15.5,0.5 15.5,13.5 "
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="5.5"
          x2="9.5"
          y1="6.5"
          y2="6.5"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="5.5"
          x2="9.5"
          y1="9.5"
          y2="9.5"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="5.5"
          x2="9.5"
          y1="12.5"
          y2="12.5"
        />
      </g>
    </svg>
  );
}

export default Copy;
