import { useState } from "react";
import { colors } from "../settings";
import { SketchPicker } from "react-color";
import "./Input.css";

export const Input = (props) => {
  switch (props.type) {
    case "select":
      return <InputSelect {...props} />;
    case "range":
      return <InputRange {...props} />;
    case "color":
      return <InputColor {...props} />;
    default:
      break;
  }
};

const InputWrapper = ({ children, label, type }) => (
  <div className={`Input ${"Input--" + type}`}>
    <div className="Input-label">
      <label htmlFor={label}>{label}</label>
    </div>
    <div className="Input-interface">{children}</div>
  </div>
);

export const InputSelect = ({ label, name, value, attributes, onChange }) => (
  <InputWrapper label={label} type="select">
    <select id={name} name={name} value={value} onChange={onChange}>
      {attributes.options.map((option, index) => (
        <option key={index} value={option.name}>
          {option.name}
        </option>
      ))}
    </select>
  </InputWrapper>
);

export const InputRange = ({ label, name, attributes, value, onChange }) => (
  <InputWrapper label={label} type="range">
    <input
      type="range"
      name={name}
      min={attributes.range ? attributes.range[0] : "0"}
      max={attributes.range ? attributes.range[1] : "100"}
      value={value}
      onChange={onChange}
    />
    <span>{value}</span>
  </InputWrapper>
);

export const InputColor = ({ label, name, value, onChange }) => {
  const [visibility, setVisibility] = useState(false);
  const pickerStyle = {
    default: {
      picker: {
        boxShadow: "0 0 0 1px rgba(0,0,0,.10), 0 8px 16px rgba(0,0,0,.15)",
      },
    },
  };

  return (
    <InputWrapper label={label} type="color">
      <button
        name={name}
        onClick={(event) => {
          event.preventDefault();
          setVisibility(!visibility);
        }}
      >
        <span className="color-circle" style={{ background: value }} />
        <span className="color-label">{value}</span>
      </button>
      {visibility ? (
        <div className="color-popup">
          <div
            className="color-cover"
            onClick={() => setVisibility(!visibility)}
          />
          <SketchPicker
            color={value}
            styles={pickerStyle}
            presetColors={Object.keys(colors)
              .map((key) => colors[key])
              .flat()}
            width={250}
            onChange={(color) => {
              onChange({
                target: { value: color.hex, name: name },
              });
            }}
          />
        </div>
      ) : null}
    </InputWrapper>
  );
};
