export const ratios = [
  { name: "Keynote", width: 1920, height: 1080 },
  { name: "Landscape", width: 1200, height: 600 },
  { name: "Portrait", width: 600, height: 1200 },
  { name: "Square", width: 1200, height: 1200 },
  { name: "Notion", width: 2400, height: 400 },
  { name: "Custom" },
];

export const colors = {
  blue: [
    "#e3f1fc",
    "#c2d8ea",
    "#a1c0d8",
    "#80a8c6",
    "#5f8fb4",
    "#3f7397",
    "#205779",
    "#003b5c",
    "#00304d",
    "#00263e",
  ],
  purple: [
    "#fbf2ff",
    "#e8daee",
    "#d1b3dc",
    "#b382c6",
    "#a66dbd",
    "#9958b3",
    "#894aa2",
    "#77408d",
    "#663778",
    "#542D63",
  ],
  red: [
    "#ffebeb",
    "#ffc2c2",
    "#ff9999",
    "#ff7777",
    "#ff5555",
    "#ee4040",
    "#df2d2d",
    "#bf2626",
    "#9f2020",
    "#801a1a",
  ],
  yellow: [
    "#fff6dd",
    "#ffefaa",
    "#ffe677",
    "#ffe055",
    "#ffd82a",
    "#ffcc00",
    "#ffb400",
    "#ff9900",
    "#d46d00",
    "#aa4000",
  ],
  green: [
    "#eafaea",
    "#bbebc8",
    "#8cdca6",
    "#5ece84",
    "#2fbf62",
    "#00b040",
    "#009a38",
    "#008030",
    "#007028",
    "#006020",
  ],
  grey: [
    "#ffffff",
    "#f9f9f9",
    "#f2f2f2",
    "#ebebeb",
    "#dddddd",
    "#bbbbbb",
    "#999999",
    "#666666",
    "#333333",
    "#000000",
  ],
  // alpha: ["transparent"],
};
