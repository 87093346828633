import { useState } from "react";
import { toPng } from "html-to-image";
import { toSvg } from "../utilities/toSvg";
import {
  stringifyVariables,
  stringifyDimensions,
  stringifyTimestamp,
} from "../utilities/stringify";
import download from "downloadjs";

import { ratios } from "../settings";
import patterns from "../patterns";

import Actions from "../components/Actions";
import Canvas from "../components/Canvas";
import Dimensions from "../components/Dimensions";
import Inputs from "../components/Inputs";
import Patterns from "../components/Patterns";
import Presets from "../components/Presets";

import "./App.css";

const App = () => {
  const [iteration, setIteration] = useState(0);
  const [pattern, setPattern] = useState(patterns[0]);
  const [presets, setPresets] = useState(pattern.presets);
  const [preset, setPreset] = useState(presets[0]);
  const [inputs, setInputs] = useState(pattern.variables);
  const [variables, setVariables] = useState({
    ...pattern.variables.reduce(
      (object, item) => ({
        ...object,
        [item["name"]]: preset[item["name"]],
      }),
      {}
    ),
  });
  const [dimensions, setDimensions] = useState({
    height: ratios[0].height,
    width: ratios[0].width,
    ratio: ratios[0].name,
  });

  const handlePatternChange = (event) => {
    const pattern = patterns.find(
      (pattern) => pattern.name === event.target.value
    );
    setPattern(pattern);
    setPresets(pattern.presets);
    setPreset(pattern.presets[0]);
    setInputs(pattern.variables);
    setVariables({ ...pattern.presets[0] });
  };

  const handlePresetChange = (event) => {
    const preset = presets.find((preset) => preset.name === event.target.value);
    setPreset(preset);
    setVariables({ ...preset });
  };

  const handleVariableChange = (event) => {
    const { name, value } = event.target;
    setVariables({ ...variables, [name]: value });
  };

  const handleDimensionChange = (event) => {
    const { name, value } = event.target;
    setDimensions({ ...dimensions, [name]: value });
  };

  const handleRatioChange = (event) => {
    const ratio = ratios.find((ratio) => ratio.name === event.target.value);
    setDimensions({
      ratio: ratio.name,
      width: ratio.width ? ratio.width : dimensions.width,
      height: ratio.height ? ratio.height : dimensions.height,
    });
  };

  const handleRefresh = () => {
    setIteration(iteration + 1);
  };

  const handleExport = (type) => {
    const selector = ".Canvas svg";
    const options = {
      width: dimensions.width,
      height: dimensions.height,
      pixelRatio: 2,
    };
    const filename = [
      pattern.name,
      stringifyVariables(variables),
      stringifyDimensions(dimensions),
      stringifyTimestamp(new Date()),
    ].join(" ");
    switch (type) {
      case "png":
        toPng(document.querySelector(selector), options).then((blob) => {
          download(blob, filename + "." + type);
        });
        break;
      case "svg":
        toSvg(document.querySelector(selector), options).then((blob) => {
          download(blob, filename + "." + type);
        });
        break;
      case "copy":
        toSvg(document.querySelector(selector), options).then((blob) => {
          navigator.clipboard.writeText(blob);
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="App">
      <main className="App-main">
        <Dimensions
          ratios={ratios}
          dimensions={dimensions}
          onRatioChange={handleRatioChange}
          onDimensionChange={handleDimensionChange}
        />
        <Canvas
          Output={pattern.Output}
          dimensions={dimensions}
          variables={variables}
        />
      </main>
      <aside className="App-aside">
        <Patterns
          pattern={pattern}
          patterns={patterns}
          onPatternChange={handlePatternChange}
        />
        <Presets
          preset={preset}
          presets={presets}
          onPresetChange={handlePresetChange}
        />
        <Inputs
          inputs={inputs}
          variables={variables}
          onVariableChange={handleVariableChange}
        />
        <Actions onExport={handleExport} onRefresh={handleRefresh} />
      </aside>
    </div>
  );
};

export default App;
