import { randomNormal } from "d3-random";

export function randNorm(mu, sigma) {
  return randomNormal(mu, sigma);
}

// Returns random float between a range.
export function randF(min, max) {
  if (min === undefined) {
    min = 1;
  }
  if (max === undefined) {
    max = min;
    min = 0;
  }
  return min + Math.random() * (max - min);
}

// Returns random integer between a range.
export function randI(min, max) {
  if (min === undefined) {
    min = 1;
  }
  if (max === undefined) {
    max = min;
    min = 0;
  }
  if (max < min) {
    let temp = min;
    min = max;
    max = temp;
  }
  return Math.round(min + Math.floor(Math.random() * (max + 1 - min)));
}
