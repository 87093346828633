import "./Presets.css";
import { Input } from "./Input";

const Presets = ({ preset, presets, onPresetChange }) => (
  <div className="Presets">
    <Input
      type="select"
      name="preset"
      label="Preset"
      value={preset.name}
      attributes={{ options: presets }}
      onChange={onPresetChange}
    />
  </div>
);

export default Presets;
