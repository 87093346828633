import { createNoise2D } from "simplex-noise";
import { randI, randF } from "./random";
import {
  normalizeVector,
  multiplyVector,
  duplicateVector,
  addVectors,
} from "../helpers/vector";

export function NoiseField(density) {
  let seed = randF(100);
  this.get = (x, y) => {
    const noiseFn = createNoise2D();
    return (noiseFn(seed + x * density, seed + y * density) + 1) * 0.5;
  };
}

export function getNoiseyStops(
  position,
  direction,
  maxStopInt,
  noiseField,
  noiseThreshold
) {
  let started = false;
  let veclength = 0;
  let noisePosition = duplicateVector(position);
  let moveDirection = normalizeVector(direction);
  let outPosition = noisePosition;

  for (let i = 0; i <= maxStopInt; ++i) {
    let val = noiseField.get(noisePosition[0], noisePosition[1]);

    if (!started && val > noiseThreshold) {
      started = true;
      outPosition = noisePosition;
    }

    if (started) {
      veclength++;
    }

    if (started && val < noiseThreshold) {
      started = false;
      break;
    }

    noisePosition = addVectors(noisePosition, moveDirection);
  }

  return [
    duplicateVector(outPosition),
    multiplyVector(normalizeVector(direction), veclength),
  ];
}

export function randomizeDir() {
  let x = 1;
  let y = 1;
  while (x !== 0 && y !== 0) {
    x = randI(-1, 1);
    y = randI(-1, 1);
  }
  return [x, y];
}

export function lerpDir(d1, d2, t) {
  return t < 0.5 ? d1 : d2;
}

export function roundPos(vec) {
  return [Math.round(vec[0]), Math.round(vec[1])];
}
