import { rotationFromVector } from "../helpers/vector";

class Grid {
  constructor({ barThickness }) {
    this.barThickness = barThickness;
    this.barThicknessHalf = barThickness / 2;
  }

  getThickness = () => this.barThickness;

  getTransform = (pos, dir) => {
    let rotationDeg = rotationFromVector(dir);
    let tx = this.barThicknessHalf + pos[0] * this.barThickness;
    let ty = this.barThicknessHalf + pos[1] * this.barThickness;
    return `translate(${tx}, ${ty}) rotate(${rotationDeg})`;
  };

  getExtent = (dir) => {
    let [dx, dy] = dir;
    let length = Math.abs(dx) + Math.abs(dy);

    if (dx !== 0 && dy !== 0) {
      console.log(
        "A problem has occurred, Grid.getExtent returns the manhattan distance, but you probably want the euclidean distance"
      );
    }
    return length * this.barThickness;
  };
}

export default Grid;
