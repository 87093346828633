import React from "react";

const FrontGradient = ({ colorStart, colorStop }) => {
  return (
    <linearGradient x1="0" y1="0" x2="1" y2="0" id="frontGradient">
      <stop offset="0" stopColor={colorStart} stopOpacity="0"></stop>
      <stop offset="1" stopColor={colorStop} stopOpacity="0.25"></stop>
    </linearGradient>
  );
};

export default FrontGradient;
