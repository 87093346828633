import "./Action.css";

const Action = ({ title, icon, onClick }) => (
  <button className="Action" onClick={onClick}>
    <span className="Action__Icon">{icon}</span>
    <span className="Action__Title">{title}</span>
  </button>
);

export default Action;
