import "./Actions.css";
import Action from "./Action";
import Refresh from "../icons/refresh";
import Download from "../icons/download";
import Copy from "../icons/copy";

const Actions = ({ onExport, onRefresh }) => (
  <div className="Actions">
    <div className="Actions__Refresh">
      <Action title="Refresh" icon={<Refresh />} onClick={onRefresh} />
    </div>
    <div className="Actions__Export">
      <Action title="PNG" icon={<Download />} onClick={() => onExport("png")} />
      <Action title="SVG" icon={<Download />} onClick={() => onExport("svg")} />
      <Action title="URL" icon={<Copy />} onClick={() => onExport("copy")} />
    </div>
  </div>
);

export default Actions;
