import "./Patterns.css";
import { Input } from "./Input";

const Patterns = ({ pattern, patterns, onPatternChange }) => (
  <div className="Patterns">
    <Input
      type="select"
      name="pattern"
      label="Pattern"
      value={pattern.name}
      attributes={{ options: patterns }}
      onChange={onPatternChange}
    />
  </div>
);

export default Patterns;
