import React from "react";
import _ from "underscore";

import { randNorm } from "../helpers/random";
import { lerpVectors } from "../helpers/vector";
import {
  NoiseField,
  getNoiseyStops,
  roundPos,
  randomizeDir,
  lerpDir,
} from "../helpers/view";

import Bar from "./Bar";

const Bars = ({ config, grid, fill }) => {
  let noiseField = new NoiseField(config.noiseDensity);
  let normRNG = randNorm(config.distMean, config.distStdDev);

  const bars = [];

  const addBar = (pos, dir) => {
    bars.push({
      curved: Math.random() < config.curveThreshold,
      thickness: grid.getThickness(),
      extent: grid.getExtent(dir),
      transform: grid.getTransform(pos, dir),
      fill: fill,
    });
  };

  const addGridBars = (naturalPos, naturalDir) => {
    let randomPos = [
      Math.round(normRNG() * config.numCols),
      Math.round(normRNG() * config.numRows),
    ];
    let randomDir = randomizeDir();
    let mergePos = roundPos(lerpVectors(naturalPos, randomPos, config.xpness));
    let mergeDir = lerpDir(naturalDir, randomDir, config.xpness);
    let [pos, dir] = getNoiseyStops(
      mergePos,
      mergeDir,
      config.maxBars,
      noiseField,
      config.noiseThreshold
    );
    addBar(pos, dir);
  };

  const addExtraBars = () => {
    let randomPos = [
      Math.round(normRNG() * config.numCols),
      Math.round(normRNG() * config.numRows),
    ];
    let randomDir = randomizeDir();
    let [pos, dir] = getNoiseyStops(
      randomPos,
      randomDir,
      config.maxBars,
      noiseField,
      config.noiseThreshold
    );
    addBar(pos, dir);
  };

  // Add horizontal bars
  for (let yPos = 0; yPos < config.numRows; ++yPos) {
    let naturalPos = [(yPos % 2) * (config.numRows - 1), yPos];
    let naturalDir = [(yPos % 2) * -2 + 1, 0];
    addGridBars(naturalPos, naturalDir);
  }

  // Add vertical bars
  for (let xPos = 0; xPos < config.numCols; ++xPos) {
    let naturalPos = [xPos, ((xPos + 1) % 2) * (config.numCols - 1)];
    let naturalDir = [0, ((xPos + 1) % 2) * -2 + 1];
    addGridBars(naturalPos, naturalDir);
  }

  // Add extra bars
  let extraBars = config.extraBars;
  while (extraBars--) {
    addExtraBars();
  }

  _.shuffle(bars);

  return (
    <React.Fragment>
      {bars.map((bar, i) => (
        <Bar key={`bar-${i}`} {...bar} />
      ))}
    </React.Fragment>
  );
};

export default Bars;
