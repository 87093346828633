import { format } from "date-fns";

export function stringifyVariables(variables) {
  return "(" + Object.values(variables).join() + ")";
}

export function stringifyDimensions(dimensions) {
  return "(" + dimensions.width + "×" + dimensions.height + ")";
}

export function stringifyTimestamp(date) {
  return "(" + format(date, "yyyy-MM-dd HH.mm.ss") + ")";
}
