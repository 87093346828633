import React from "react";

const Bar = ({ curved, thickness, extent, transform, fill }) => {
  let path;
  let negY = -(thickness / 2);
  if (curved) {
    path =
      extent === 0
        ? ``
        : `
      M ${negY} , ${negY}
      l ${extent - thickness} , ${0}
      a ${thickness} , ${thickness} ${0} ${0} ${1} ${thickness} , ${thickness}
      l ${-extent} , ${0}
      Z
    `;
  } else {
    path = `
      M ${negY} , ${negY}
      l ${extent} , ${0}
      l ${0} , ${thickness}
      l ${-extent} , ${0}
      Z
    `;
  }
  return <path d={path} fill={fill} transform={transform} />;
};

export default Bar;
