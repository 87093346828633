import React from "react";

function Download() {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Download</title>
      <g>
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="8"
          x2="8"
          y1="0.5"
          y2="11.5"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="0.5"
          x2="15.5"
          y1="15.5"
          y2="15.5"
        />
        <polyline
          fill="none"
          points="3.5 7 8 11.5 12.5 7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default Download;
