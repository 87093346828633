import { Input } from "./Input";
import "./Inputs.css";

const Inputs = ({ inputs, variables, onVariableChange }) => (
  <form className="Inputs">
    {inputs.map((input, index) => (
      <Input
        key={index}
        name={input.name}
        label={input.label}
        type={input.type}
        value={variables[input.name]}
        onChange={onVariableChange}
        attributes={input.attributes}
      />
    ))}
  </form>
);

export default Inputs;
