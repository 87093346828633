// Calculate radians from degrees
const RAD_TO_DEG = 180 / Math.PI;
export function degrees(r) {
  return r * RAD_TO_DEG;
}

// Calculate degrees from radians
const DEG_TO_RAD = Math.PI / 180;
export function radians(d) {
  return d * DEG_TO_RAD;
}

// Linear interpolation
export function lerp(n1, n2, t) {
  return n1 + (n2 - n1) * t;
}

// Has to offset by one, must look at the sign of
export function intLerp(i1, i2, t) {
  let dist = i2 - i1;
  let round = dist < 0 ? Math.ceil : Math.floor;
  let add = dist === 0 ? 0 : dist < 0 ? -1 : 1;
  return i1 + round((dist + add) * t);
}

// sin(x * pi/2) ^ 3
export function cubesin(t) {
  return Math.pow(Math.sin(t * (0.5 * Math.PI)), 3);
}
export function cubsinerp(n1, n2, t) {
  return lerp(n1, n2, cubesin(t));
}

// 1 - cos(x * pi/2) ^ 3
export function invcubecos(t) {
  return 1 - Math.pow(Math.cos(t * (0.5 * Math.PI)), 3);
}
export function invcubcoserp(n1, n2, t) {
  return lerp(n1, n2, invcubecos(t));
}
