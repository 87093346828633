import { degrees, lerp } from "./math";

export function normalizeVector(vec) {
  var length = Math.sqrt(vec[0] * vec[0] + vec[1] * vec[1]);
  if (length === 0) return [0, 0];
  return [vec[0] / length, vec[1] / length];
}

export function duplicateVector(vec) {
  return vec.slice();
}

export function multiplyVector(vec, num) {
  return [vec[0] * num, vec[1] * num];
}

export function addVectors(vec1, vec2) {
  return [vec1[0] + vec2[0], vec1[1] + vec2[1]];
}

export function lerpVectors(vec1, vec2, t) {
  return [lerp(vec1[0], vec2[0], t), lerp(vec1[1], vec2[1], t)];
}

export function rotationFromVector(vec) {
  return degrees(Math.atan2(vec[1], vec[0]));
}
