import { colors } from "../../settings.js";

import generateConfig from "./generateConfig";
import Grid from "./components/Grid";
import Bars from "./components/Bars";
import FrontGradient from "./components/FrontGradient";
import BackGradient from "./components/BackGradient";

export const name = "Woven";

export const variables = [
  {
    label: "Expressiveness",
    name: "expressiveness",
    type: "range",
    attributes: {
      range: [1, 100],
    },
  },
  {
    label: "Resolution",
    name: "resolution",
    type: "range",
    attributes: {
      range: [1, 10],
    },
  },
  {
    label: "Foreground Start",
    name: "fgStart",
    type: "color",
  },
  {
    label: "Foreground Stop",
    name: "fgStop",
    type: "color",
  },
  {
    label: "Background Start",
    name: "bgStart",
    type: "color",
  },
  {
    label: "Background Stop",
    name: "bgStop",
    type: "color",
  },
];

export const presets = [
  {
    name: "Blue",
    expressiveness: 50,
    resolution: 5,
    bgStart: colors.blue[2],
    bgStop: colors.blue[7],
    fgStart: colors.grey[0],
    fgStop: colors.grey[0],
  },
  {
    name: "Red",
    expressiveness: 50,
    resolution: 5,
    bgStart: colors.red[2],
    bgStop: colors.red[7],
    fgStart: colors.grey[0],
    fgStop: colors.grey[0],
  },
  {
    name: "Yellow",
    expressiveness: 50,
    resolution: 5,
    bgStart: colors.yellow[2],
    bgStop: colors.yellow[7],
    fgStart: colors.grey[0],
    fgStop: colors.grey[0],
  },
  {
    name: "Green",
    expressiveness: 50,
    resolution: 5,
    bgStart: colors.green[2],
    bgStop: colors.green[7],
    fgStart: colors.grey[0],
    fgStop: colors.grey[0],
  },
  {
    name: "Purple",
    expressiveness: 50,
    resolution: 5,
    bgStart: colors.purple[2],
    bgStop: colors.purple[7],
    fgStart: colors.grey[0],
    fgStop: colors.grey[0],
  },
  {
    name: "Grey",
    expressiveness: 50,
    resolution: 5,
    bgStart: colors.grey[4],
    bgStop: colors.grey[7],
    fgStart: colors.grey[0],
    fgStop: colors.grey[0],
  },
];

export const Output = ({ dimensions, variables }) => {
  const config = generateConfig(dimensions, variables);
  const grid = new Grid({ barThickness: config.barThickness });
  // Convenience shortcuts
  const w = dimensions.width;
  const h = dimensions.height;
  const d = config.diagonal;
  // Inline attributes
  const viewbox = `0 0 ${w} ${h}`;
  const translation = `translate(-${(d - w) / 2}, -${(d - h) / 2})`;
  const rotation = `rotate(45, ${d / 2}, ${d / 2})`;
  return (
    <svg viewBox={viewbox}>
      <defs>
        <FrontGradient
          colorStart={variables.fgStart}
          colorStop={variables.fgStop}
        />
        <BackGradient
          colorStart={variables.bgStart}
          colorStop={variables.bgStop}
        />
      </defs>
      <g id="background-group">
        <rect
          id="background-rect"
          width={w}
          height={h}
          fill="url(#backGradient)"
        />
      </g>
      <g id="foreground-translation-group" transform={translation}>
        <g id="foreground-rotation-group" transform={rotation}>
          <Bars config={config} grid={grid} fill="url(#frontGradient)" />
        </g>
      </g>
    </svg>
  );
};
